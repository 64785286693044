import { themes } from "@/constants";
import { CHANGE_THEME } from "../constants/actions";
import { GET_IS_DARK_MODE, GET_SYSTEM_THEME, GET_THEME, GET_LANGUAGE } from "../constants/getters";
import { SET_SYSTEM_THEME, SET_THEME, SET_LANGUAGE } from "../constants/mutations";

export default {
  state: {
    theme: themes.system_preference.value,
    system_theme: themes.light.value,
    language: 'id',
  },
  getters: {
    [GET_LANGUAGE](state){ return state.language },
    [GET_THEME](state){ return state.theme },
    [GET_SYSTEM_THEME](state){ return state.system_theme },
    [GET_IS_DARK_MODE](state){ return false /*state.theme!=themes.system_preference.value ? state.theme==themes.dark.value : state.system_theme==themes.dark.value*/ },
  },
  actions: {
    [CHANGE_THEME](context){
      if(context.state.theme==themes.light.value){
        context.commit(SET_THEME,themes.dark.value)
      } else if(context.state.theme==themes.dark.value){
        context.commit(SET_THEME,themes.system_preference.value)
      } else {
        context.commit(SET_THEME,themes.light.value)
      }
    },
  },
  mutations: {
    [SET_LANGUAGE](state,value){
      state.language=value ?? ''
    },
    [SET_THEME](state,value){
      state.theme=value ?? ''
    },
    [SET_SYSTEM_THEME](state,value){
      state.system_theme=value ?? ''
    },
  },
};