/**
    *
    *
    * @param {String} type // enterance, exits and attention
    * @return {Object} 
    */
export function randomModalAnimationClasses(type){
  const animations = {
    enterance: [
      'animate__backInDown',
      'animate__backInLeft',
      'animate__backInRight',
      'animate__backInUp',
      'animate__bounceIn',
      'animate__bounceInDown',
      'animate__bounceInDown',
      'animate__bounceInRight',
      'animate__bounceInUp',
      'animate__fadeIn',
      'animate__fadeInDown',
      'animate__fadeInDownBig',
      'animate__fadeInLeft',
      'animate__fadeInLeftBig',
      'animate__fadeInRight',
      'animate__fadeInRightBig',
      'animate__fadeInUp',
      'animate__fadeInUpBig',
      'animate__fadeInTopLeft',
      'animate__fadeInTopRight',
      'animate__fadeInBottomLeft',
      'animate__fadeInBottomRight',
      'animate__flipInX',
      'animate__flipInY',
      'animate__lightSpeedInRight',
      'animate__lightSpeedInLeft',
      'animate__rotateIn',
      'animate__rotateInDownLeft',
      'animate__rotateInDownRight',
      'animate__rotateInUpLeft',
      'animate__rotateInUpRight',
      'animate__rollIn',
      'animate__zoomIn',
      'animate__zoomInDown',
      'animate__zoomInLeft',
      'animate__zoomInRight',
      'animate__zoomInUp',
      'animate__slideInDown',
      'animate__slideInLeft',
      'animate__slideInRight',
      'animate__slideInUp',

      'animate__jackInTheBox',
    ],
    exits: [
      'animate__backOutDown',
      'animate__backOutLeft',
      'animate__backOutRight',
      'animate__backOutUp',
      'animate__bounceOut',
      'animate__bounceOutDown',
      'animate__bounceOutDown',
      'animate__bounceOutRight',
      'animate__bounceOutUp',
      'animate__fadeOut',
      'animate__fadeOutDown',
      'animate__fadeOutDownBig',
      'animate__fadeOutLeft',
      'animate__fadeOutLeftBig',
      'animate__fadeOutRight',
      'animate__fadeOutRightBig',
      'animate__fadeOutUp',
      'animate__fadeOutUpBig',
      'animate__fadeOutTopLeft',
      'animate__fadeOutTopRight',
      'animate__fadeOutBottomLeft',
      'animate__fadeOutBottomRight',
      'animate__flipOutX',
      'animate__flipOutY',
      'animate__lightSpeedOutRight',
      'animate__lightSpeedOutLeft',
      'animate__rotateOut',
      'animate__rotateOutDownLeft',
      'animate__rotateOutDownRight',
      'animate__rotateOutUpLeft',
      'animate__rotateOutUpRight',
      'animate__rollOut',
      'animate__zoomOut',
      'animate__zoomOutDown',
      'animate__zoomOutLeft',
      'animate__zoomOutRight',
      'animate__zoomOutUp',
      'animate__slideOutDown',
      'animate__slideOutLeft',
      'animate__slideOutRight',
      'animate__slideOutUp',
      
      'animate__hinge',
    ],
    attention: [
      'animate__flip',
      'animate__bounce',
      'animate__flash',
      'animate__pulse',
      'animate__rubberBand',
      'animate__shakeX',
      'animate__shakeY',
      'animate__headShake',
      'animate__swing',
      'animate__tada',
      'animate__wobble',
      'animate__jello',
      'animate__heartBeat',
    ],
  }
  const animation_types = animations[type]
  if(!animation_types) return animations.attention[0]
  return animation_types[Math.floor(Math.random()*animation_types.length)];
}