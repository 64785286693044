
import store from '@/store';
import { GET_IS_LOGGED_IN } from '@/store/constants';
import { ROUTER_LOGGER } from '@/services';
import { routes_names } from '../constants';

export function beforeEach(to, from, next){
  ROUTER_LOGGER.log(from.name,to.name)
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
	const notRequiresAuth = to.matched.some((record) => record.meta.notRequiresAuth)
	const is_logged_in = store.getters[GET_IS_LOGGED_IN]
	ROUTER_LOGGER.log({
		is_logged_in,
		requiresAuth,
		notRequiresAuth,
	})
	if(requiresAuth && !is_logged_in) {
		next({
			name: routes_names.login
		});
	} else if( (notRequiresAuth && is_logged_in) || to.matched.length==0) {
		next({
			name: routes_names.main
		});
	} else {
		next();
	}
}