<template>
  <div 
    id="app" 
    :class="{
      'electron-layout': isElectron
    }"
  >
    <ElectronCommon v-if="isElectron" />
    <div class="h-100 w-100 main-app">
      <router-view />
    </div>
  </div>
</template>

<script>
import { API } from '@/services'
import ElectronCommon from '@/components/electron/index.vue';
import { mapGetters, mapMutations } from 'vuex';
import { GET_IS_DARK_MODE, GET_LANGUAGE, SET_SYSTEM_THEME } from './store/constants';
import { MEDICAL_HEALTH_SOCKET } from './sockets';
export default {
  name: 'App',
  components: {
    ElectronCommon,
  },
  computed: {
    ...mapGetters([
      GET_IS_DARK_MODE,
      GET_LANGUAGE
    ]),
    themeClass(){
      if(this.GET_IS_DARK_MODE) return 'dark-theme'
      else return 'light-theme'
    },
    isElectron(){ return process.env.IS_ELECTRON ? true : false },
  },
  watch: {
    "themeClass": {
      immediate: true,
      handler(theme_class,old_theme_class){
        if(document.body.classList.contains(old_theme_class)) {
          document.body.classList.replace(old_theme_class,theme_class)
        } else {
          document.body.classList.add(theme_class)
        }
      }
    },
  },
  methods: {
    ...mapMutations([
      SET_SYSTEM_THEME
    ]),
    init(){
      API.init()
      MEDICAL_HEALTH_SOCKET.init()
      this.$i18n.locale=this.GET_LANGUAGE
    },
    onChangeSystemThemeMode(){
      const system_theme = window?.matchMedia?.('(prefers-color-scheme: dark)')?.matches ? 'dark' : 'light'
      this.SET_SYSTEM_THEME(system_theme)
    },
  },
  created(){
    this.init()
    this.onChangeSystemThemeMode()
    window?.matchMedia?.('(prefers-color-scheme: dark)')?.addEventListener?.('change', this.onChangeSystemThemeMode);
    window?.matchMedia?.('(prefers-color-scheme: colored)')?.addEventListener?.('change', this.onChangeSystemThemeMode);
  },
  beforeDestroy () {
    window?.matchMedia?.('(prefers-color-scheme: dark)')?.removeEventListener?.('change', this.onChangeSystemThemeMode);
    window?.matchMedia?.('(prefers-color-scheme: colored)')?.removeEventListener?.('change', this.onChangeSystemThemeMode);
  },
}
</script>

<style lang="scss">
@import '@/scss/index.scss';
body {
  height: 100vh;
  width: 100vw;
  // font-family: cursive, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-family: Poppins, serif, Times;
}
#app {
  height: 100%;
  width: 100%;
}
</style>
