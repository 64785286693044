<template>
  <div ref="side-bar" :class="`md-sideBar ${minimizeSidebar ? 'minimizedVersion' : ''}`">
    <div class="md-sideBar-inner">
      <div class="sideBar-userInfo">
        <div class="sideBar-userInfo-inner">
          <b-dropdown v-if="GET_USER" variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <div class="sideBar-userInfo-leftSide">
                <div class="userInfo-initials"  @click.stop="minimizeSidebar ? minimizeSidebar = !minimizeSidebar : ''">
                  <div class="userInfo-initials-text">{{ GET_USER.first_name | firstCharacter }}{{ GET_USER.last_name | firstCharacter }}</div>
                </div>
                <div class="userInfo-nameEmail">
                  <p class="userInfo-name mb-0">{{GET_USER.first_name}} {{ GET_USER.last_name }}</p>
                  <p class="userInfo-email mb-0">{{ GET_USER | get_property('emails[0].email') }}</p>
                </div>
              </div>
            </template>
            <b-dropdown-item @click="LOGOUT()">Logout</b-dropdown-item>
          </b-dropdown>
          <b-button variant="link" @click="minimizeSidebar = !minimizeSidebar">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-state="closed">
              <rect width="18" height="18" x="3" y="3" rx="2"></rect>
              <path d="M9 3v18"></path>
              <path d="m16 15-3-3 3-3"></path>
            </svg>
          </b-button>
        </div>
      </div>
      <div class="sideBar-devider"></div>
      <div class="sideBar-scrollSection">
        <div class="sideBar-scrollSection-topSection">
          <button class="smallFullWidthBlackButton" @click="createSession()">
            <b-icon icon="plus-circle" />
            <span>New session</span>
          </button>
          <ul class="sideBar-list">
            <li class="sideBar-list-item justify-content-between" @click="$refs['side-bar'].classList.contains('SubSideBarIsActive')?$refs['side-bar'].classList.remove('SubSideBarIsActive'):$refs['side-bar'].classList.add('SubSideBarIsActive')">
              <div class="d-flex justify-content-between align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-round-search ">
                  <circle cx="10" cy="8" r="5"></circle>
                  <path d="M2 21a8 8 0 0 1 10.434-7.62"></path>
                  <circle cx="18" cy="18" r="3"></circle>
                  <path d="m22 22-1.9-1.9"></path>
                </svg>
                <span class="ml-2">View sessions</span>
              </div>
              <span class="sideBar-list-item-text d-flex justify-content-between align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right text-font-secondary"><path d="m9 18 6-6-6-6"></path></svg>
              </span>
            </li>
          </ul>
          <div class="sideBar-list-label">
            <span>Templates</span>
          </div>
          <ul class="sideBar-list">
            <router-link class="sideBar-list-item" exact-active-class="active" :to="{ name: routesNames.templates }">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-blocks ">
                <rect width="7" height="7" x="14" y="3" rx="1"></rect>
                <path d="M10 21V8a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H3"></path>
              </svg>
              <span class="sideBar-list-item-text">Template library</span>
            </router-link>
            <router-link class="sideBar-list-item" exact-active-class="active" :to="{ name: routesNames.community }">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-globe ">
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20"></path>
                <path d="M2 12h20"></path>
              </svg>
              <span class="sideBar-list-item-text">Community</span>
            </router-link>
          </ul>
          <div class="sideBar-list-label">
            <span>Settings</span>
          </div>
          <ul class="sideBar-list">
            <router-link class="sideBar-list-item" exact-active-class="active" :to="{ name: routesNames.account }">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-settings "><path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path><circle cx="12" cy="12" r="3"></circle></svg>
              <span class="sideBar-list-item-text">Account</span>
            </router-link>
            <router-link class="sideBar-list-item" exact-active-class="active" :to="{ name: routesNames.preferences }">
              <svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="16.5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-settings2 "><path d="M20 7h-9"></path><path d="M14 17H5"></path><circle cx="17" cy="17" r="3"></circle><circle cx="7" cy="7" r="3"></circle></svg>
              <span class="sideBar-list-item-text">Preferences</span>
            </router-link>
            <router-link class="sideBar-list-item" exact-active-class="active" :to="{ name: routesNames.memory }">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-brain-circuit text-font-secondary"><path d="M12 5a3 3 0 1 0-5.997.125 4 4 0 0 0-2.526 5.77 4 4 0 0 0 .556 6.588A4 4 0 1 0 12 18Z"></path><path d="M9 13a4.5 4.5 0 0 0 3-4"></path><path d="M6.003 5.125A3 3 0 0 0 6.401 6.5"></path><path d="M3.477 10.896a4 4 0 0 1 .585-.396"></path><path d="M6 18a4 4 0 0 1-1.967-.516"></path><path d="M12 13h4"></path><path d="M12 18h6a2 2 0 0 1 2 2v1"></path><path d="M12 8h8"></path><path d="M16 8V5a2 2 0 0 1 2-2"></path><circle cx="16" cy="13" r=".5"></circle><circle cx="18" cy="3" r=".5"></circle><circle cx="20" cy="21" r=".5"></circle><circle cx="20" cy="8" r=".5"></circle></svg>
              <span class="sideBar-list-item-text">Memory</span>
            </router-link>
            <router-link class="sideBar-list-item" exact-active-class="active" :to="{ name: routesNames.team }">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-building "><rect width="16" height="20" x="4" y="2" rx="2" ry="2"></rect><path d="M9 22v-4h6v4"></path><path d="M8 6h.01"></path><path d="M16 6h.01"></path><path d="M12 6h.01"></path><path d="M12 10h.01"></path><path d="M12 14h.01"></path><path d="M16 10h.01"></path><path d="M16 14h.01"></path><path d="M8 10h.01"></path><path d="M8 14h.01"></path></svg>
              <span class="sideBar-list-item-text">Team</span>
            </router-link>
            <router-link class="sideBar-list-item" exact-active-class="active" :to="{ name: routesNames.integrations }">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plug-zap "><path d="M6.3 20.3a2.4 2.4 0 0 0 3.4 0L12 18l-6-6-2.3 2.3a2.4 2.4 0 0 0 0 3.4Z"></path><path d="m2 22 3-3"></path><path d="M7.5 13.5 10 11"></path><path d="M10.5 16.5 13 14"></path><path d="m18 3-4 4h6l-4 4"></path></svg>
              <span class="sideBar-list-item-text">Integrations</span>
            </router-link>
          </ul>
        </div>
        <div class="sideBar-scrollSection-bottomSection">
          <div class="sideBar-devider"></div>
          <ul class="sideBar-list">
            <li class="sideBar-list-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-badge-dollar-sign text-font-secondary"><path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z"></path><path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8"></path><path d="M12 18V6"></path></svg>
              <span>Earn $50</span>
            </li>
            <li class="sideBar-list-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-map text-font-secondary"><path d="M14.106 5.553a2 2 0 0 0 1.788 0l3.659-1.83A1 1 0 0 1 21 4.619v12.764a1 1 0 0 1-.553.894l-4.553 2.277a2 2 0 0 1-1.788 0l-4.212-2.106a2 2 0 0 0-1.788 0l-3.659 1.83A1 1 0 0 1 3 19.381V6.618a1 1 0 0 1 .553-.894l4.553-2.277a2 2 0 0 1 1.788 0z"></path><path d="M15 5.764v15"></path><path d="M9 3.236v15"></path></svg>
              <span>Roadmap</span>
            </li>
            <li class="sideBar-list-item justify-content-between">
              <div class="d-flex justify-content-between align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-command "><path d="M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3"></path></svg>
                <span class="ml-2">Shortcuts</span>
              </div>
              <span>S</span>
            </li>
          </ul>
          <button class="smallFullWidthGreyButton">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-help "><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><path d="M12 17h.01"></path></svg>
            <span>Help</span>
          </button>
        </div>
      </div>
    </div>
    <div class="md-sideBar-inner sessionsSectionPart">
      <div class="SubSideBar-heading">Sessions</div>
      <div class="whiteBGInput-withIcon-container mt-3 w-100">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-search text-font-secondary"><circle cx="11" cy="11" r="8"></circle><path d="m21 21-4.3-4.3"></path></svg>
        <input placeholder="Search" style="width: calc(100% - 8px - 16px);min-width: auto" />
      </div>
      <div class="minimaltabsHeader">
        <div class="tabsHeader-item w-50">
          <div class="tabsHeader-item-text">Schedule</div>
        </div>
        <div class="tabsHeader-item w-50 active">
          <div class="tabsHeader-item-text">Past</div>
        </div>
      </div>
      <div class="slidingCheckbox-container">
        <router-link tag="div" v-for="session in GET_SESSIONS" :key="session.id" :to="{ name: routesNames.session, params: { session_id: session.id }}">
          <div class="slidingCheckbox-bigText">{{session.patient_name || session.name}}</div>
          <div class="slidingCheckbox-smallText">{{session.created_at | date_format}}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ADD_SESSION, GET_IS_MAIN_USER, GET_SESSIONS, GET_USER, LOGOUT } from '@/store/constants'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { sidebar } from '@/constants'
import { routes_names } from '@/router/constants'
import { API } from '@/services'
export default {
  name: 'Sidebar',
  data() {
    return {
      api: {
        create_session: this.$appInstance.api({  }),
      },
      minimizeSidebar: false
    }
  },
  computed: {
    ...mapGetters([
      GET_USER,
      GET_IS_MAIN_USER,
      GET_SESSIONS,
    ]),
    routesNames(){ return routes_names },
    sidebar(){ return sidebar },
  },
  filters: {
    firstCharacter(value) { return value?.at?.(0)?.toUpperCase?.() || '' }
  },
  methods: {
    ...mapActions([
      LOGOUT,
    ]),
    ...mapMutations([
      ADD_SESSION,
    ]),
    async createSession(){
      if(this.api.create_session.send) return;
      try {
        this.api.create_session.send=true
        const { data: session } = await API.endpoints.sessions.create()
        this.ADD_SESSION(session)
        if(!this.$refs['side-bar'].classList.contains('SubSideBarIsActive')) this.$refs['side-bar'].classList.add('SubSideBarIsActive')
        this.$router.push({ 
          name: routes_names.session, 
          params: { 
            session_id: session.id 
          } 
        })
      } catch (ex) {
        this.$appToaster({
          message: ex.own_message || ex.message, 
          type: 'error',
        })
      } finally {
        this.api.create_session.send=false
      }
    },
  },
}
</script>

<style lang="scss">
@import '~/node_modules/bootstrap/scss/_functions.scss';
@import '~/node_modules/bootstrap/scss/_variables.scss';
.sidebar {
  grid-area: sideBar;
  color: $black;
  padding-left: 15px;
  > ul {
    list-style: none;
    padding: 0px;
    background: linear-gradient(to right,  $light-card 0%, $light-card 50%, $white 50%, $white 100%);
    > li {
      &:not(.router-link-exact-active):hover {
        padding: 2px;
        padding-right: 15px;
        > div {
          background-color: #ffffffaa;
          padding: 8px;
          border-radius: 50px;
        }
      }
      background-color: $light-card;
      padding: 10px;
      &.for-border {
        height: 20px;
      }
      &:has(+ .router-link-exact-active) {
        border-bottom-right-radius: 20px;
      }
      &.router-link-exact-active + li {
        border-top-right-radius: 20px;
      }
      &.router-link-exact-active {
        background-color: $white;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
      }
    }
  }
}
.md-sideBar{
  height: 100vh;
  width: 200px;
  grid-area: sideBar;
  .md-sideBar-inner{
    padding-top: 1rem;
    padding-bottom: .5rem;
    padding-left: .5rem;
    padding-right: .5rem;
    border-right-width: 1px;
    border-right: 1px solid rgb(226, 232, 240);
    background-color: white;

    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 200px;
    height: 100%;
  }
  .sideBar-scrollSection{
    width: -webkit-fill-available;
    overflow-y: auto;
    justify-content: space-between;
    flex-direction: column;
    flex: 1 1 0%;
    height: 100%;
    display: flex;
    .sideBar-scrollSection-topSection{
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .sideBar-scrollSection-bottomSection{
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 16px;
    }
  }
  &.SubSideBarIsActive{
    display: flex;
    width: 400px;
    .md-sideBar-inner{
      width: 50%;
    }
  }
}
.sideBar-userInfo{
  width: 100%;
  .sideBar-userInfo-inner{
    width: 100%;
    padding-left: .5rem;
    padding-right: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sideBar-userInfo-leftSide{
      gap: .5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: calc(100% - 18px - 8px);
      .userInfo-initials{
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;

        border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        .userInfo-initials-text{
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
        }
      }
      .userInfo-nameEmail{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: calc(100% - 32px - 8px);
        .userInfo-name{
          font-size: 12px;
          font-weight: 500;
          line-height: 1.3;
          color: rgb(2, 8, 23);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
        .userInfo-email{
          font-size: 10px;
          font-weight: 400;
          line-height: 1.3;
          color: rgb(100 116 139);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
      }
    }
    svg{
      cursor: pointer;
    }
  }
}
.sideBar-devider{
  height: 1px;
  background-color: rgb(226, 232, 240);
}
.sideBar-list-label{
  font-size: 10px;
  line-height: 1.3;
  font-weight: 500;
  color: rgb(100, 116, 139);
  padding: 0px 6px 4px 6px;
}
.sideBar-list{
  width: 100%;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  .sideBar-list-item{
    width: 100%;
    font-size: 12px;
    line-height: 1.3;
    font-weight: 500;
    padding: 12px;
    white-space: nowrap;
    column-gap: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 32px;
    color: rgb(100, 116, 139);
    margin-bottom: 1px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    &:hover , &.active{
      background-color: rgb(241, 245, 249);
      color: rgb(15, 23, 42);
    }
    // .sideBar-list-item-text{

    // }
    svg{
      height: 16px;
      width: 16px;
    }
  }
}

.SubSideBar-heading{
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  color: rgb(2, 8, 23);
}
.slidingCheckbox-container{
  .slidingCheckbox-bigText{
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3;
    color: rgb(2, 8, 23);
  }
  .slidingCheckbox-smallText{
    font-size: 10px;
    font-weight: 400;
    line-height: 1.3;
    color: rgb(100, 116, 139);
  }
  .custom-control.custom-checkbox{
    padding: 4px 10px;
    
		-webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    transition: all 0.25s ease-in-out;
    .custom-control-label{
      &::before{
        opacity: 0;
        transition: all .1s ease-in-out;
        transition-delay: 0.1s;
      }
      &::after{
        opacity: 0;
        transition: all .1s ease-in-out;
        transition-delay: 0.1s;
      }
    }
    &:hover{
      background-color: rgb(241, 245, 249);
      padding-left: 32px;
      .custom-control-label{
        &::before{
          opacity: 1;
          transition: all .1s ease-in-out;
          transition-delay: 0.1s;
        }
        &::after{
          opacity: 1;
          transition: all .1s ease-in-out;
          transition-delay: 0.1s;
        }
      }
    }
  }
}
</style>