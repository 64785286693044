/**
    *
    *
    * @param {{ data: Object }} message
    * @return {Object} 
    */
export function formInstance(data={}){
  const data1 = JSON.parse(JSON.stringify(data))
  let obj = {
    ...data1,
    submitted: false
  }
  const reset = function(){
    const data2 = JSON.parse(JSON.stringify(data))
    let obj1 = {
      ...obj,
      ...data2,
      submitted: false
    }
    Object.assign(obj,obj1)
  }
  obj.reset = reset.bind(obj)
  return obj
}