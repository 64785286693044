<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="$attrs['width'] ? $attrs['width'] : 16"
    :height="$attrs['height'] ? $attrs['height'] : 16"
    :viewBox="$attrs['viewBox'] ? $attrs['viewBox'] : '0 0 24 24'"
    :fill="$attrs['fill'] ? $attrs['fill'] : 'none'"
    :stroke="$attrs['stroke'] ? $attrs['stroke'] : 'currentColor'"
    :stroke-width="$attrs['stroke-width'] ? $attrs['stroke-width'] : 2"
    :stroke-linecap="$attrs['stroke-linecap'] ? $attrs['stroke-linecap'] : 'round'"
    :stroke-linejoin="$attrs['stroke-linejoin'] ? $attrs['stroke-linejoin'] : 'round'"
    :class="[$attrs['class'],iicc.c]"
    v-html="iicc.icon"
  ></svg>
</template>

<script>
export default {
  name: "AppIcon",
  props: {
    icon: {
      type: String,
      default: "",
    },
  },
  computed: {
    iicc(){
      let icon='', c=''
      switch (this.icon) {
        case 'transcription':
          icon=`<path d="M2 10v3"></path><path d="M6 6v11"></path><path d="M10 3v18"></path><path d="M14 8v7"></path><path d="M18 5v13"></path><path d="M22 10v3"></path>`;
          c='lucide lucide-audio-lines text-font-secondary group-hover:text-rose-500 group-data-[state=active]:text-rose-500';
          break;
        case 'context':
          icon=`<path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>`;
          c='lucide lucide-audio-lines text-font-secondary group-hover:text-rose-500 group-data-[state=active]:text-rose-500';
          break;
        case 'note':
          icon=`<path d="M12 20h9"></path><path d="M16.5 3.5a2.12 2.12 0 0 1 3 3L7 19l-4 1 1-4Z"></path><path d="m15 5 3 3"></path>`;
          c='lucide lucide-pencil-line text-font-secondary group-hover:text-sky-500 group-data-[state=active]:text-sky-500';
          break;
        default:
          break;
      }
      return {
        class: c,
        icon
      }
    },
  },
};
</script>

<style>
</style>