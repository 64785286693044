import { routes_names } from "@/router/constants";

export const sidebar = [
  {
    text: 'Dashboard',
    icon: 'house-fill',
    to: {
      name: routes_names.main,
    },
  },
  {
    text: 'Template Library',
    icon: 'house-fill',
    to: {
      name: routes_names.templates,
    },
  },
  {
    text: 'Community',
    icon: 'house-fill',
    to: {
      name: routes_names.community,
    },
  },
  {
    text: 'Account',
    icon: 'house-fill',
    to: {
      name: routes_names.account,
    },
  },
  {
    text: 'Preferences',
    icon: 'house-fill',
    to: {
      name: routes_names.preferences,
    },
  },
  {
    text: 'Memory',
    icon: 'house-fill',
    to: {
      name: routes_names.memory,
    },
  },
  {
    text: 'Team',
    icon: 'house-fill',
    to: {
      name: routes_names.team,
    },
  },
  {
    text: 'Integrations',
    icon: 'house-fill',
    to: {
      name: routes_names.integrations,
    },
  },
]