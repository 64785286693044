import { HELPER_FUNCTIONS } from "@/utils/index"

export async function toaster(payload={}) {
  let that = this
  const message = payload?.message ?? ''
  const type = payload?.type
  const is_audio = payload?.audio ?? true
  const position = payload?.position
  const id = HELPER_FUNCTIONS.getUniqueID() ?? payload?.id
  const types = {
    info: {
      text: 'Info',
      class: 'app-info-toast',
      variant: 'primary',
    },
    success: {
      text: 'Success',
      class: 'app-success-toast',
      variant: 'success',
    },
    error: {
      text: 'Error',
      class: 'app-error-toast',
      variant: 'danger',
    },
    warning: {
      text: 'Warning',
      class: 'app-warning-toast',
      variant: 'warning',
    },
  }
  const positions = {
    "top-right": {
      position: "b-toaster-top-right",
      animation: {
        enterance: 'animate__slideInRight',
        exit: 'animate__slideInLeft',
      },
      append: true,
    },
    "top-left": {
      position: "b-toaster-top-left",
      animation: {
        enterance: 'animate__slideInLeft',
        exit: 'animate__slideInRight',
      },
      append: true,
    },
    "top-center": {
      position: "b-toaster-top-center",
      animation: {
        enterance: 'animate__slideInDown',
        exit: 'animate__slideInUp',
      },
      append: true,
    },
    "top-full": {
      position: "b-toaster-top-full",
      animation: {
        enterance: 'animate__slideInDown',
        exit: 'animate__slideInUp',
      },
      append: true,
    },
    "bottom-right": {
      position: "b-toaster-bottom-right",
      animation: {
        enterance: 'animate__slideInRight',
        exit: 'animate__slideInLeft',
      },
      append: false,
    },
    "bottom-left": {
      position: "b-toaster-bottom-left",
      animation: {
        enterance: 'animate__slideInLeft',
        exit: 'animate__slideInRight',
      },
      append: false,
    },
    "bottom-center": {
      position: "b-toaster-bottom-center",
      animation: {
        enterance: 'animate__slideInUp',
        exit: 'animate__slideInDown',
      },
      append: false,
    },
    "bottom-full": {
      position: "b-toaster-bottom-full",
      animation: {
        enterance: 'animate__slideInUp',
        exit: 'animate__slideInDown',
      },
      append: false,
    },
  }
  const selected_position = positions[position] ?? positions['bottom-right']
  const selected_type = types[type] ?? types.info
  const h = that.$createElement
  const hide = () => {
    that.$bvToast.hide(id)
  }
  const vNodeTitle = h('div',{
    style: {
      display: 'none',
      padding: 0,
    },
  })
  const vNodeMessage = h('div',{
    class: 'toaster',
  },[
    h('div',{
      class: 'toaster-container'
    },[
      h('div',{
        class: 'toaster-content'
      },[
        h('div',{
          class: 'message-container'
        },[
          h('span',{
            class: 'title',
          },[
            selected_type.text
          ]),
          h('span',{
            class: 'message',
          },[
            message
          ]),
        ]),
      ]),
      h('div',{
        class: 'toaster-action'
      },[
        h('div',{
          class: 'sub-toaster-action'
        },[
          h('b-button',{
            props: {
              variant: 'link'
            },
            on: {
              click: hide
            },
          },[
            h('b-icon',{
              props: {
                icon: 'x',
                variant: selected_type.variant
              },
            })
          ]),
        ])
      ])
    ]),
    h('div',{
      class: 'toaster-progress-container'
    },[
      h('div',{
        class: 'progress'
      })
    ])
  ])
  that.$bvToast.toast([vNodeMessage], {
    ...payload,
    id: id,
    title: [vNodeTitle],
    solid: true,
    noCloseButton: true,
    noFade: true,
    appendToast: selected_position.append,
    toastClass: `app-toaster ${selected_type.class} animate__animated ${selected_position.animation.enterance}`,
    toaster: `${selected_position.position}`,
  })
  if(is_audio){
    const audio = new Audio(require('@/assets/audio/rintones/Alert 2.mp3'))
    audio.play().catch(()=>{})
  }
} 