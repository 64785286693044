import Vue from "vue";
import { pluginFactoryNoConfig } from "bootstrap-vue/src/utils/plugins";
import { 
  BIconAppDarkMode, 
  BIconAppEdit, 
  BIconAppLogout, 
  BIconAppTrash,
  BIconAppSearch,
  BIconAppRefresh,
  BIconAppAdd,
  BIconAppStar,
} from "../bootstrap/custom/icons";
const CustomIconsPlugin = pluginFactoryNoConfig({
  components: {
    BIconAppLogout,
    BIconAppTrash,
    BIconAppEdit,
    BIconAppDarkMode,
    BIconAppSearch, 
    BIconAppRefresh,
    BIconAppAdd,
    BIconAppStar,
  }
})
Vue.use(CustomIconsPlugin);