import { 
  GET_APP_VERSION, 
  GET_UPDATE_AVAILABLE_VERSION, 
  GET_UPDATE_DOWNLOAD_PROGRESS, 
  GET_UPDATE_ERROR_MESSAGE, 
  GET_UPDATE_STATUS 
} from "../constants/getters";
import { 
  SET_APP_VERSION, 
  SET_UPDATE_AVAILABLE_VERSION, 
  SET_UPDATE_DOWNLOAD_PROGRESS, 
  SET_UPDATE_ERROR_MESSAGE, 
  SET_UPDATE_STATUS 
} from "../constants/mutations";

export default {
  state: {
    update: {
      status: '',
      available_version: '',
      error_message: '',
      downloading: {
        percentage: 0,
        per_second: 0,
        downloaded: 0,
        total: 0,
      },
    },
    app_version: '',
  },
  getters: {
    [GET_UPDATE_STATUS](state){ return state.update.status },
    [GET_UPDATE_AVAILABLE_VERSION](state){ return state.update.available_version },
    [GET_APP_VERSION](state){ return state.app_version },
    [GET_UPDATE_ERROR_MESSAGE](state){ return state.update.error_message },
    [GET_UPDATE_DOWNLOAD_PROGRESS](state) { return state.update.downloading },
  },
  actions: {
    
  },
  mutations: {
    [SET_UPDATE_STATUS](state,value){
      state.update.status=value ?? ''
    },
    [SET_UPDATE_AVAILABLE_VERSION](state,value){
      state.update.available_version=value ?? ''
    },
    [SET_UPDATE_ERROR_MESSAGE](state,value){
      state.update.error_message=value ?? ''
    },
    [SET_APP_VERSION](state,value){
      state.app_version=value ?? ''
    },
    [SET_UPDATE_DOWNLOAD_PROGRESS](state,value){
      state.update.downloading=value ?? {}
    },
  },
};