export const routes_names = {
  main: 'main',
  templates: 'templates',
  community: 'community',
  preferences: 'preferences',
  account: 'account',
  session: 'session',
  memory: 'memory',
  integrations: 'integrations',
  team: 'team',
  login: 'login',
  signup: 'signup',
  verify_email: 'verify_email',
}