import { apiInstance } from "./apiInstance"
import { AUDIO_RECORDER } from "./audioRecorder"
import { formInstance } from "./formInstance"
import { getUniqueID } from "./getUniqueID"
import { parseJwt } from "./parseJwt"
import { randomModalAnimationClasses } from "./randomModalAnimationClasses"
import { sleep } from "./sleep"
import { stateInstance } from "./stateInstance"

export const HELPER_FUNCTIONS = {
  apiInstance: apiInstance,
  formInstance: formInstance,
  stateInstance: stateInstance,
  parseJwt: parseJwt,
  getUniqueID: getUniqueID,
  randomModalAnimationClasses: randomModalAnimationClasses,
  sleep: sleep,
  AUDIO_RECORDER: AUDIO_RECORDER,
}