import { API } from "@/services"

export const SESSIONS_ENDPOINTS = {
  create(data){
    return API.Axios.medical_health_api.v1.request({
      url: `sessions`,
      method: 'POST',
      data,
    })
  },
  uploadFile(session_id,data,onUploadProgress=null){
    return API.Axios.medical_health_api.v1.request({
      url: `sessions/${session_id}/upload-file`,
      method: 'PATCH',
      data,
      headers: {
        'Content-Type': 'application/form-data'
      },
      onUploadProgress,
      timeout: 5*60*60*1000,
    })
  },
  updatePatientName(session_id,data){
    return API.Axios.medical_health_api.v1.request({
      url: `sessions/${session_id}/patient-name`,
      method: 'PATCH',
      data,
    })
  },
  updateContent(session_transcription_id,data){
    return API.Axios.medical_health_api.v1.request({
      url: `sessions/${session_transcription_id}/update-content`,
      method: 'PATCH',
      data,
    })
  },
  list(params){
    return API.Axios.medical_health_api.v1.request({
      url: `sessions`,
      method: 'GET',
      params,
    })
  },
  getById(session_id,params){
    return API.Axios.medical_health_api.v1.request({
      url: `sessions/${session_id}`,
      method: 'GET',
      params,
    })
  },
  transcribe(session_id,data){
    return API.Axios.medical_health_api.v1.request({
      url: `sessions/${session_id}/transcribe`,
      method: 'PATCH',
      data,
      timeout: 5*60*60*1000,
    })
  },
  makeTemplate(session_id,template_id,data){
    return API.Axios.medical_health_api.v1.request({
      url: `sessions/${session_id}/template/${template_id}/transcribe`,
      method: 'PATCH',
      data,
      timeout: 5*60*60*1000,
    })
  },
}