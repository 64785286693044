
import Axios from 'axios';
import { API_LOGGER } from '../logger';
import { API_ENDPOINTS } from './endpoints/index';
import store from '@/store';
import { LOGOUT, GET_TOKEN, REFRESH_TOKEN } from '@/store/constants';

const timeout = 1 * 60 * 1000
const axios_name = {
  medical_health: 'medical-health',
}
export const API = {
  Axios: {
    medical_health_api: {
      v1: Axios.create({
        name: axios_name.medical_health,
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: timeout,
        version: 1,
      }),
    },
  },
  get errorMessages(){
    return {
      default: `There seems to be a issue try again later`,
      timeout: `It's taking longer than usual to connect to our servers. Please check your internet connection and try again.`,
      network: `Unable to establish a connection to our servers. Please ensure you're connected to the internet and try again.`,
      server_down: `Our servers are currently unavailable. We apologise for the inconvenience. Please try again later.`,
    }
  },
  init(){
    try {
      this.Axios.medical_health_api.v1.interceptors.request.use(this.onRequest.bind(this))
      this.Axios.medical_health_api.v1.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
    } finally {
      window.API=this
    }
  },
  async onRequest(config){
    API_LOGGER.log(config?.url,config)
    const api_name = config?.name
    const version = config?.version
    if(api_name==axios_name.medical_health) {
      const token = store.getters[GET_TOKEN]
      if(version==1){
        config.baseURL=`${process.env.VUE_APP_MEDICAL_HEALTH_API_URL}/api/v1/`
      }
      config.headers.Authorization=`Bearer ${token}`;
    }
    return config;
  },
  async onError(ex){
    API_LOGGER.danger(ex?.config?.url,ex)
    const api_name = ex?.config?.name
    const response_error = ex?.response?.data ?? {}
    const response_message = response_error?.message ?? ''
    const error_message = ex?.message ?? ''
    const status = ex?.response?.status ?? ex?.status
    const errors = response_error?.errors ?? {}
    let message = ''
    if(status==500){
      message = this.errorMessages.default
    } else if(status==502){
      message = this.errorMessages.server_down
    } else if(error_message == "Network Error"){
      if(!window?.navigator?.onLine){
        message = this.errorMessages.network
      } else {
        message = this.errorMessages.server_down
      }
    } else if(error_message.includes('timeout')){
      message = this.errorMessages.timeout
    } else {
      message = response_message || error_message
    }
    if(status==401) {
      API_LOGGER.log(status,!ex?.config?.authentication)
      if(!ex?.config?.authentication){
        if(api_name==axios_name.medical_health) {
          if(store.getters[GET_TOKEN]) {
            await store.dispatch(REFRESH_TOKEN)
            const token = store.getters[GET_TOKEN]
            ex.config.headers.Authorization=`Bearer ${token}`;
            return this.Axios.medical_health_api.v1(ex.config)
          }
        } else {
          store.dispatch(LOGOUT)
        }
      }
    }
    const response = {
      own_errors: errors,
      own_message: message,
      response_error: response_error,
      ...ex,
      message: ex.message,
      stack: ex.stack,
    }
    throw response
  },
  async onSuccess(response){
    API_LOGGER.success(response?.config?.url,response)
    return response 
  },
  endpoints: API_ENDPOINTS
}