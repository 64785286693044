import { LocalStorage } from "./storage/LocalStorage";

export const STORAGE = {
  AUTH: new LocalStorage('AUTH',{
    encrypted: true,
  }),
  APP: new LocalStorage('APP',{
    encrypted: true,
  }),
  SETTING: new LocalStorage('SETTING',{
    encrypted: true,
  }),
};