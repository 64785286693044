import { API } from "@/services"

export const AUTH_ENDPOINTS = {
  login(data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `auth/login`,
      method: 'POST',
      data,
      authentication: true,
    })
  },
  signup(data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `auth/signup`,
      method: 'POST',
      data,
      authentication: true,
    })
  },
  verifyEmail(token,data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `auth/verify-email/${token}`,
      method: 'POST',
      data,
      authentication: true,
    })
  },
  refreshToken(data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `auth/refresh-token`,
      method: 'POST',
      data,
      authentication: true,
    })
  },
  logout(data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `auth/logout`,
      method: 'POST',
      data,
      authentication: true,
    })
  },
}