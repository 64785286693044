import { STORAGE } from '@/services';
import createPersistedState from 'vuex-persistedstate';


export default createPersistedState({
  key: STORAGE.SETTING.key,
  paths: [
    `setting.theme`,
    `setting.language`,
  ],
  storage: {
    getItem: (key) => key==STORAGE.SETTING.key ? STORAGE.SETTING.get() : '',
    setItem: (key, value) => key==STORAGE.SETTING.key ? STORAGE.SETTING.set(value) : '',
    removeItem: (key) => key==STORAGE.SETTING.key ? STORAGE.SETTING.remove() : '',
  }
});