export const send_events = {
  CHECKING_FOR_UPDATE: 'CHECKING_FOR_UPDATE',
  UPDATE_AVAILABLE: 'UPDATE_AVAILABLE',
  UPDATE_NOT_AVAILABLE: 'UPDATE_NOT_AVAILABLE',
  UPDATE_DOWNLOAD_PROGRESS: 'UPDATE_DOWNLOAD_PROGRESS',
  UPDATE_DOWNLOADED: 'UPDATE_DOWNLOADED',
  UPDATE_ERROR: 'UPDATE_ERROR',
  APP_VERSION: 'APP_VERSION',
  MAIN_WINDOW_MAXIMIZE: 'MAIN_WINDOW_MAXIMIZE',
  MAIN_WINDOW_UNMAXIMIZE: 'MAIN_WINDOW_UNMAXIMIZE',
  MAIN_WINDOW_MINIMIZE: 'MAIN_WINDOW_MINIMIZE',
  MAIN_WINDOW_FOCUS: 'MAIN_WINDOW_FOCUS',
  MAIN_WINDOW_BLUR: 'MAIN_WINDOW_BLUR',
}