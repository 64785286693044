import { io } from "socket.io-client";
import Emitter from 'component-emitter';
import { GET_TOKEN } from "@/store/constants";
import store from "@/store";
export const MEDICAL_HEALTH_SOCKET = {
  socket: null,
  init(){
    Emitter(this)
    window.MEDICAL_HEALTH_SOCKET = this
    return this
  },
  connect(){
    const token = store.getters[GET_TOKEN]
    const url = new URL(process.env.VUE_APP_MEDICAL_HEALTH_API_URL)
    this.socket = io(`${url.origin}`,{
      autoConnect: false,
      closeOnBeforeunload: true,
      reconnection: true,
      reconnectionDelayMax: 10000,
      reconnectionAttempts: 5,
      auth: {
        token: token
      },
    });
    this.socket.connect()
  },
  disconnect(){
    this.socket?.disconnect?.()
  },
}