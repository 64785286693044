<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="4">
        <b-input-group>
          <b-input-group-prepend>
            <div class="h-100 d-flex justify-content-center align-items-center pr-2">
              <b-icon icon="app-search" />
            </div>
          </b-input-group-prepend>
          <b-input
            v-model="filter.search"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-dropdown>
              <template #button-content>
                {{ filter.fields.length==0?'All':filter.fields.length }} Fields
              </template>
              <b-dropdown-form @submit="''">
                <b-checkbox-group v-model="filter.fields">
                  <b-checkbox v-for="field in filterableFields" :key="field.key" :value="field.key"><p>{{ field.label || field.key }}</p></b-checkbox>
                </b-checkbox-group>
              </b-dropdown-form>
            </b-dropdown>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <div v-if="paginate" class="d-flex align-items-center mr-2">
          <p class="sm m-0 p-0">{{ recordStartAndEnd.start }} to {{ recordStartAndEnd.end }} of {{ pagination.total }}</p>
          <b-pagination
            class="ml-2 m-0 p-0"
            v-model="pagination.page"
            :total-rows="pagination.total"
            :per-page="pagination.per_page"
          />
          <b-select class="ml-2 w-auto m-0" v-model="pagination.per_page" :options="paginationOptions" />
        </div>
        <div class="d-flex align-items-center">
          <slot name="append-input">
            
          </slot>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          sticky-header
          class="app-table"
          :dark="GET_IS_DARK_MODE"
          :striped="true"
          :bordered="true"
          :borderless="true"
          responsive
          :busy="loading"
          show-empty
          :current-page="pagination.page"
          :per-page="pagination.per_page"
          :filter="filter.search"
          :filter-included-fields="filter.fields"
          :sort-by.sync="sort.by"
          :sort-desc.sync="sort.desc"
          :items="items"
          :fields="fields"
          @filtered="onFiltered($event)"
        >
          <template #table-busy>
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton-table
                  :dark="GET_IS_DARK_MODE"
                  :rows="skeletonRows"
                  :columns="skeletonColumn ? skeletonColumn : fields.length"
                  hide-header
                  :table-props="{ 
                    bordered: true, 
                    borderless: true,
                    striped: true, 
                  }"
                />
              </template>
            </b-skeleton-wrapper>
          </template>
          <template v-for="(slot,key) in $scopedSlots" v-slot:[key]="row">
            <slot :name="key" :row="row"></slot>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { GET_IS_DARK_MODE } from '@/store/constants'
import { mapGetters } from 'vuex'
export default {
  name: 'AppTable',
  model: {
    prop: 'search',
    event: 'update:search',
  },
  props: {
    paginate: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      default: ()=>[],
    },
    items: {
      type: [Array,Function],
      default: ()=>[],
    },
    skeletonRows: {
      type: Number,
      default: 10,
    },
    skeletonColumn: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    'update:search',
    'onAdd',
    'onRefresh',
  ],
  data() {
    return {
      pagination: {
        per_page: 10,
        page: 1,
        total: 0,
      },
      filter: {
        search: '',
        fields: [],
      },
      sort: {
        by: '',
        desc: false,
      }
    }
  },
  watch: {
    "items": {
      immediate: true,
      handler(items){
        if(typeof this.items != 'function') {
          this.pagination.total=items.length
        }
      }
    }
  },
  methods: {
    onFiltered(event){
      if(typeof this.items != 'function') {
        this.pagination.total=event.length;
        this.pagination.page=1;
      }
    },
    setPagination({ total, per_page, page }) {
      this.pagination.total=total || 1
      this.pagination.page=page || 1
      this.pagination.per_page=per_page || 10
    }
  },
  computed: {
    ...mapGetters([
      GET_IS_DARK_MODE
    ]),
    recordStartAndEnd(){
      const total = this.pagination.total
      const page = this.pagination.page
      const per_page = this.pagination.per_page
      const end_index = page * per_page - 1
      const start_index = end_index - per_page + 1
      const start = start_index + 1
      const end = end_index + 1
      return {
        start: start>total ? total : start,
        end: end>total ? total : end,
      }
    },
    filterableFields(){ return this.fields.filter(i=>i.filterable) },
    paginationOptions(){ return [10,50,100] },
  },
}
</script>

<style lang="scss">
.app-table {
  td {
    text-wrap: nowrap;
  }
  th {
    text-wrap: nowrap;
  }
}
</style>