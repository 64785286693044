import Vue from "vue";
import enMessage from "./lang/en";
import idMessage from "./lang/id";
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
export const languages = {
  'en': {
    name: 'English',
    code: 'en',
    messages: enMessage
  },
  'id': {
    name: 'Indonesian',
    code: 'id',
    messages: idMessage
  },
}
export default new VueI18n({
  fallbackLocale: 'en',
  messages: {
    'en': languages.en.messages,
    'id': languages.id.messages,
  },
})