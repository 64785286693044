export class ConsoleLogger {
  #levels = {
    warn: {
      name: 'warn', 
      bg_color: 'yellow', 
      text_color: 'black',
    },
    danger: {
      name: 'danger', 
      bg_color: 'red', 
      text_color: 'white'
    },
    success: {
      name: 'success', 
      bg_color: 'green', 
      text_color: 'white'
    },
    info: {
      name: 'info', 
      bg_color: 'blue', 
      text_color: 'white',
    },
    debug: {
      name: 'debug', 
      bg_color: 'gray', 
      text_color: 'white',
    },
    event: {
      name: 'event', 
      bg_color: 'purple', 
      text_color: 'white',
    },
    watch: {
      name: 'watch', 
      bg_color: 'wheat', 
      text_color: 'black',
    },
    hook: {
      name: 'hook', 
      bg_color: 'black', 
      text_color: 'white',
    },
    default: {
      name: 'info', 
      bg_color: 'white', 
      text_color: 'black',
    },
    default1: { 
      name: 'info', 
      bg_color: 'orange', 
      text_color: 'black',
    },
    default2: { 
      name: 'info', 
      bg_color: 'green', 
      text_color: 'white',
    },
    log: { 
      name: 'info', 
      bg_color: '#F28B54', 
      text_color: 'white',
    },
    log1: { 
      name: 'info', 
      bg_color: '#ffc107', 
      text_color: 'black',
    },
    log2: { 
      name: 'info', 
      bg_color: '#7367f0', 
      text_color: 'white',
    },
    connected: { 
      name: 'connected', 
      bg_color: '#57bbac', 
      text_color: 'black',
    },
    disconnected: { 
      name: 'disconnected', 
      bg_color: '#290000', 
      text_color: 'white',
    },
  }
  name=''
  console=true
  constructor(options={}){
    this.name=options.name || ''
    this.console=!!(options.console ?? true)
  }
  consoling(level_text, ...args) {
    if(this.console){
      const level = this.#levels[level_text]
      const styling = `background-color:${level.bg_color};color:${level.text_color};padding:5px;font-weight:bold;`
      const default_styling = `background-color:#5576D1;color:white;padding:5px;font-weight:bold;`
      const name_styling = `background-color:#113a1b;color:white;padding:5px;font-weight:bold;`
      const app_name = "CENTERAL PORTAL"
      if(this.name){
        console.log(`%c${app_name}`+`%c${this.name}`+`%c${level.name.toUpperCase()}`,default_styling,name_styling,styling,...args)
      } else {
        console.log(`%c${app_name}`+`%c${level.name.toUpperCase()}`,default_styling,styling,...args)
      }
    }
  }
  warn(...args){ this.consoling('warn',...args) }
  danger(...args){ this.consoling('danger',...args) }
  success(...args){ this.consoling('success',...args) }
  info(...args){ this.consoling('info',...args) }
  debug(...args) { this.consoling('debug',...args) }
  unique(...args){ this.consoling('unique',...args) }
  event(...args){ this.consoling('event',...args) }
  watch(...args){ this.consoling('watch',...args) }
  hook(...args){ this.consoling('hook',...args) }
  default(...args){ this.consoling('default',...args) }
  default1(...args){ this.consoling('default1',...args) }
  default2(...args){ this.consoling('default2',...args) }
  log(...args){ this.consoling('log',...args) }
  log1(...args){ this.consoling('log1',...args) }
  log2(...args){ this.consoling('log2',...args) }
  connected(...args){ this.consoling('connected',...args) }
  disconnected(...args){ this.consoling('disconnected',...args) }
}