import Vue from 'vue';
import Vuex from 'vuex';

import authPlugin from './plugins/auth'
import authModule from './modules/auth'

import appPlugin from './plugins/app'
import appModule from './modules/app'

import settingPlugin from './plugins/setting'
import settingModule from './modules/setting'

import electronModule from './modules/electron'

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    auth: authModule,
    app: appModule,
    setting: settingModule,
    electron: electronModule,
  },
  plugins: [
    authPlugin,
    appPlugin,
    settingPlugin,
  ],
});