import { API } from "@/services";
import keyBy from 'lodash.keyby'
import { FETCH_FAVOURITE_TEMPLATES, FETCH_SESSIONS, FETCH_START_UP_RECORD, FETCH_TEMPLATES, FETCH_USER, RESET_START_UP_RECORD } from "../constants/actions";
import { GET_FAVOURITE_TEMPLATES, GET_FETCH_START_UP_RECORD, GET_SESSIONS, GET_TEMPLATES, GET_TOKEN_PAYLOAD, GET_USER } from "../constants/getters";
import { SET_USER, SET_FETCH_START_UP_RECORD, SET_TEMPLATES, SET_SESSIONS, ADD_SESSION, UPDATE_SESSION, SET_FAVOURITE_TEMPLATES, REMOVE_TEMPLATE } from "../constants/mutations";
export default {
  state: {
    fetch_start_up_record: false,
    user: {},
    templates: {},
    favourite_templates: [],
    sessions: {},
  },
  getters: {
    [GET_FETCH_START_UP_RECORD](state){ return state.fetch_start_up_record },
    [GET_USER](state){ return state.user },
    [GET_TEMPLATES](state){ return state.templates },
    [GET_FAVOURITE_TEMPLATES](state){ return state.favourite_templates },
    [GET_SESSIONS](state){ return state.sessions },
  },
  actions: {
    async [FETCH_USER](context){
      try {
        const { user_id: id } = context.rootGetters[GET_TOKEN_PAYLOAD]
        const { data: user } = await API.endpoints.users.findById(id)
        context.commit(SET_USER,user)
      } catch (ex) {
        console.log('error fetch user',ex)
      }
    },
    async [FETCH_TEMPLATES](context){
      const { data: templates } = await API.endpoints.templates.list()
      const filter_templates = keyBy(templates,'id')
      context.commit(SET_TEMPLATES,filter_templates)
    },
    async [FETCH_FAVOURITE_TEMPLATES](context){
      const { data: favourites_templates } = await API.endpoints.templates.favourites()
      const favourites_template_ids = favourites_templates.map(i=>i.template_id)
      context.commit(SET_FAVOURITE_TEMPLATES,favourites_template_ids)
    },
    async [FETCH_SESSIONS](context){
      const { data: sessions } = await API.endpoints.sessions.list()
      const filter_sessions = keyBy(sessions,'id')
      context.commit(SET_SESSIONS,filter_sessions)
    },
    async [FETCH_START_UP_RECORD](context){
      try {
        context.commit(SET_FETCH_START_UP_RECORD,true)
        await context.dispatch(FETCH_USER)
        await context.dispatch(FETCH_TEMPLATES)
        await context.dispatch(FETCH_FAVOURITE_TEMPLATES)
        await context.dispatch(FETCH_SESSIONS)
      } finally {
        context.commit(SET_FETCH_START_UP_RECORD,false)
      }
    },
    async [RESET_START_UP_RECORD](context) {
      context.commit(SET_USER)
      context.commit(SET_TEMPLATES)
      context.commit(SET_FAVOURITE_TEMPLATES)
      context.commit(SET_SESSIONS)
    },
  },
  mutations: {
    [SET_FETCH_START_UP_RECORD](state,value){
      state.fetch_start_up_record=!!value
    },
    [SET_USER](state,value){
      state.user=value ?? {}
    },
    [SET_TEMPLATES](state,value){
      state.templates=value ?? {}
    },
    [REMOVE_TEMPLATE](state,template_id){
      delete state.templates[template_id]
    },
    [SET_FAVOURITE_TEMPLATES](state,value){
      state.favourite_templates=value ?? {}
    },
    [SET_SESSIONS](state,value){
      state.sessions=value ?? {}
    },
    [ADD_SESSION](state,value){
      state.sessions={
        ...state.sessions,
        [value.id]: value,
      }
    },
    [UPDATE_SESSION](state,{ id, key, value }){
      state.sessions[id][key]=value
    },
  },
};