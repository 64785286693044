// SECTION - AUTH
export const LOGOUT = 'LOGOUT'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
// SECTION - APP
export const FETCH_START_UP_RECORD = 'FETCH_START_UP_RECORD'
export const RESET_START_UP_RECORD = 'RESET_START_UP_RECORD'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_TEMPLATES = 'FETCH_TEMPLATES'
export const FETCH_FAVOURITE_TEMPLATES = 'FETCH_FAVOURITE_TEMPLATES'
export const FETCH_SESSIONS = 'FETCH_SESSIONS'
// SECTION - SETTING
export const CHANGE_THEME = 'CHANGE_THEME'