import { HELPER_FUNCTIONS } from "@/utils/index"

export async function confirmation(message='', options={}) {
  let that = this
  const modalId = options.id ?? `modalId-${HELPER_FUNCTIONS.getUniqueID()}`
  const animation_class = HELPER_FUNCTIONS.randomModalAnimationClasses('attention')
  const hide_animation_class = HELPER_FUNCTIONS.randomModalAnimationClasses('exits')
  const default_options = {
    id: modalId,
    okTitle: 'Yes',
    cancelTitle: 'No',
    centered: true,
    modalClass: `animate__animated ${animation_class}`
  }
  let password='', confirm=null, force_hidden = false
  async function onHidden(bvModalEvent){
    if(bvModalEvent.componentId==modalId){
      if(!force_hidden){
        bvModalEvent.target.classList.replace(animation_class,hide_animation_class)
        bvModalEvent.preventDefault()
        await HELPER_FUNCTIONS.sleep()
        that.$root.$off('bv::modal::hide',onHidden)
        force_hidden=true
        confirm = bvModalEvent.trigger=='ok' ? true : bvModalEvent.trigger=='cancel' ? false : null
        that.$bvModal.hide(modalId)
      }
    }
  }
  that.$root.$on('bv::modal::hide',onHidden)
  if(options.auth) {
    const h = that.$createElement
    const el = h('div',{
      
    },[
      h('div',{ 
        class: 'd-flex justify-content-center mb-2'
      },[
        `${message}`
      ]),
      h('b-row',{ 
        
      },[
        h('b-input',{ 
          domProps: {
            placeholder: 'Enter Password',
            type: 'password',
            value: password,
          },
          on: {
            keyup: (event)=>{
              if(event.keyCode==13) {
                that.$bvModal.hide(modalId)
              }
            },
            input: (value) => {
              password=value
            }
          }
        },[
          
        ]),
      ]),
    ])
    await that.$bvModal.msgBoxConfirm(el, {
      ...default_options,
      ...options,
    })
  } else {
    await that.$bvModal.msgBoxConfirm(message, {
      ...default_options,
      ...options,
    })
  }
  return { confirm, password }
} 