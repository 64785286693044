<template>
  <div class="d-flex justify-content-between electron-title-bar">
    <div class="d-flex justify-content-center align-items-center">
      <b-img-lazy :src="require('@/assets/images/vb-logo.svg')" height="30" width="30" class="ml-2" />
    </div>
    <div class="d-flex">
      <!-- <span class="color-white">{{GET_UPDATE_STATUS}}</span>
      <span class="color-white">{{GET_UPDATE_AVAILABLE_VERSION}}</span>
      <span class="color-white">{{GET_APP_VERSION}}</span>
      <span class="color-white">{{GET_UPDATE_DOWNLOAD_PROGRESS.percentage}}</span>
      <span class="color-white">{{GET_UPDATE_ERROR_MESSAGE.percentage}}</span>
      <b-button @click="checkUpdate()">
        Check Update
      </b-button>
      <b-icon v-if="GET_UPDATE_STATUS=='check-for-update'" icon="app-refresh" animation="spin-pulse" />
      <template v-else-if="GET_UPDATE_STATUS=='download-progress'">
        <b-progress :value="GET_UPDATE_DOWNLOAD_PROGRESS.percentage" :max="100" show-progress animated></b-progress>
        <span>{{ GET_UPDATE_AVAILABLE_VERSION }}</span>
      </template>
      <template v-else-if="GET_UPDATE_STATUS=='error'">
        <span>{{ GET_UPDATE_ERROR_MESSAGE }}</span>
      </template> -->
    </div>
    <div class="d-flex w-100 title-dragable" />
    <div class="d-flex">
      <b-button variant="link" class="mr-2" @click="CHANGE_THEME()">
        <b-icon :icon="GET_THEME==themes.light.value?'sun-fill':GET_THEME==themes.dark.value?'app-dark-mode':'circle-half'" :color="GET_IS_DARK_MODE?'white':'black'" />
      </b-button>
      <b-button v-if="GET_TOKEN" variant="link" @click="LOGOUT()">
        <b-icon icon="box-arrow-left" variant="danger" />
      </b-button>
    </div>
    <div class="d-flex electron-actions ml-3">
      <b-button variant="link" @click="minimize()" class="mr-3">
        <b-icon icon="caret-down-fill" />
      </b-button>
      <b-button variant="link" @click="toggleMaximize()" class="mr-3">
        <b-icon v-if="maximize" icon="fullscreen-exit" />
        <b-icon v-else icon="fullscreen" />
      </b-button>
      <b-button variant="link" @click="close()" class="close-btn mr-3">
        <b-icon variant="danger" icon="x" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { send_events } from '@/electron/events/send'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { recieve_events } from '@/electron/events/recieve'
import { 
  GET_APP_VERSION, 
  GET_IS_DARK_MODE, 
  GET_THEME, 
  GET_TOKEN, 
  GET_UPDATE_AVAILABLE_VERSION, 
  GET_UPDATE_DOWNLOAD_PROGRESS, 
  GET_UPDATE_ERROR_MESSAGE, 
  GET_UPDATE_STATUS,
  SET_APP_VERSION, 
  SET_UPDATE_AVAILABLE_VERSION, 
  SET_UPDATE_DOWNLOAD_PROGRESS, 
  SET_UPDATE_ERROR_MESSAGE, 
  SET_UPDATE_STATUS,
  CHANGE_THEME, 
  LOGOUT 
} from '@/store/constants'
import { ELECTRON_LOGGER } from '@/services'
import { themes } from '@/constants'
export default {
  name: 'ElectronCommon',
  data() {
    return {
      maximize: false,
      focus: true,
    }
  },
  computed: {
    ...mapGetters([
      GET_UPDATE_STATUS,
      GET_UPDATE_DOWNLOAD_PROGRESS,
      GET_UPDATE_AVAILABLE_VERSION,
      GET_UPDATE_ERROR_MESSAGE,
      GET_APP_VERSION,
      GET_THEME,
      GET_IS_DARK_MODE,
      GET_TOKEN,
    ]),
    themes(){ return themes },
  },
  methods: {
    ...mapMutations([
      SET_APP_VERSION,
      SET_UPDATE_AVAILABLE_VERSION,
      SET_UPDATE_DOWNLOAD_PROGRESS,
      SET_UPDATE_ERROR_MESSAGE,
      SET_UPDATE_STATUS
    ]),
    ...mapActions([
      CHANGE_THEME,
      LOGOUT,
    ]),
    checkUpdate(){
      window.electron.send(recieve_events.CHECK_UPDATE)
    },
    minimize(){
      window.electron.send(recieve_events.MINIMZE_MAIN_WINDOW)
    },
    toggleMaximize(){
      if(this.maximize) {
        window.electron.send(recieve_events.UNMAXIMIZE_MAIN_WINDOW)
      } else {
        window.electron.send(recieve_events.MAXIMIZE_MAIN_WINDOW)
      }
    },
    close(){
      window.electron.send(recieve_events.CLOSE_MAIN_WINDOW)
    },
    onKeyDown(event){
      const { key, shiftKey, ctrlKey } = event
      if(process.env.IS_ELECTRON){
        if(key=='R' && shiftKey && ctrlKey){
          event.preventDefault()
        } else if(key=='I' && shiftKey && ctrlKey){
          event.preventDefault()
        } else if(key=='r' && ctrlKey){
          event.preventDefault()
        }
      }
    },
    CHECK_FOR_UPDATE(){
      ELECTRON_LOGGER.log('CHECK_FOR_UPDATE')
      this.SET_UPDATE_STATUS('check-for-update')
    },
    UPDATE_AVAILABLE(event){
      ELECTRON_LOGGER.log('UPDATE_AVAILABLE')
      this.SET_UPDATE_STATUS('update-available')
      this.SET_UPDATE_AVAILABLE_VERSION(event?.app_version ?? '')
    },
    UPDATE_NOT_AVAILABLE(){
      ELECTRON_LOGGER.log('UPDATE_NOT_AVAILABLE')
      this.SET_UPDATE_STATUS('update-not-available')
    },
    UPDATE_DOWNLOAD_PROGRESS(event){
      ELECTRON_LOGGER.log('UPDATE_DOWNLOAD_PROGRESS',event)
      this.SET_UPDATE_STATUS('download-progress')
      this.SET_UPDATE_DOWNLOAD_PROGRESS({
        percentage: event?.percent ?? 0,
        per_second: event?.bytesPerSecond ?? 0,
        downloaded: event?.transferred ?? 0,
        total: event?.total ?? 0,
      })
    },
    UPDATE_DOWNLOADED(){
      ELECTRON_LOGGER.log('UPDATE_DOWNLOADED')
      this.SET_UPDATE_STATUS('update-downloaded')
    },
    UPDATE_ERROR(event){
      ELECTRON_LOGGER.log('UPDATE_ERROR',event?.message)
      this.SET_UPDATE_STATUS('error')
      this.SET_UPDATE_ERROR_MESSAGE(event?.message ?? '')
    },
    APP_VERSION(event){
      ELECTRON_LOGGER.log('APP_VERSION',event?.app_version,event?.platform)
      this.SET_APP_VERSION(event?.app_version ?? '')
    },
    MAIN_WINDOW_BLUR(){
      ELECTRON_LOGGER.log('MAIN_WINDOW_BLUR')
      this.focus=false
    },
    MAIN_WINDOW_FOCUS(){
      ELECTRON_LOGGER.log('MAIN_WINDOW_FOCUS')
      this.focus=true
    },
    MAIN_WINDOW_MAXIMIZE(){
      ELECTRON_LOGGER.log('MAIN_WINDOW_MAXIMIZE')
      this.maximize=true
    },
    MAIN_WINDOW_UNMAXIMIZE(){
      ELECTRON_LOGGER.log('MAIN_WINDOW_UNMAXIMIZE')
      this.maximize=false
    },
    MAIN_WINDOW_MINIMIZE(){},
  },
  created () {
    document.addEventListener('keydown',this.onKeyDown)
    window.electron.receive(send_events.CHECKING_FOR_UPDATE,this.CHECK_FOR_UPDATE)
    window.electron.receive(send_events.UPDATE_AVAILABLE,this.UPDATE_AVAILABLE)
    window.electron.receive(send_events.UPDATE_NOT_AVAILABLE,this.UPDATE_NOT_AVAILABLE)
    window.electron.receive(send_events.UPDATE_DOWNLOAD_PROGRESS,this.UPDATE_DOWNLOAD_PROGRESS)
    window.electron.receive(send_events.UPDATE_DOWNLOADED,this.UPDATE_DOWNLOADED)
    window.electron.receive(send_events.UPDATE_ERROR,this.UPDATE_ERROR)
    window.electron.receive(send_events.APP_VERSION,this.APP_VERSION)
    window.electron.receive(send_events.MAIN_WINDOW_BLUR,this.MAIN_WINDOW_BLUR)
    window.electron.receive(send_events.MAIN_WINDOW_FOCUS,this.MAIN_WINDOW_FOCUS)
    window.electron.receive(send_events.MAIN_WINDOW_MAXIMIZE,this.MAIN_WINDOW_MAXIMIZE)
    window.electron.receive(send_events.MAIN_WINDOW_UNMAXIMIZE,this.MAIN_WINDOW_UNMAXIMIZE)
    window.electron.receive(send_events.MAIN_WINDOW_MINIMIZE,this.MAIN_WINDOW_MINIMIZE)
  },
  beforeDestroy () {
    document.removeEventListener('keydown',this.onKeyDown)
  },
}
</script>

<style lang="scss" scoped>
@import '~/node_modules/bootstrap/scss/_functions.scss';
@import '~/node_modules/bootstrap/scss/_variables.scss';
.electron-title-bar {
  height: 50px;
  background-color: $light-card;
  > .title-dragable {
    -webkit-app-region: drag;
  }
  > .electron-actions {
    align-items: center;
    > button {
      height: 40px;
      color: $black;
      border-radius: 0;
      background-color: transparent;
      border: none;
      border-radius: 50%;
      &:focus {
        box-shadow: none;
      }
      &.close-btn {
        color: $danger;
      }
    }
  }
}
</style>
<style lang="scss">
.electron-layout {
  > .main-app {
    height: calc(100% - 50px) !important;
    .dashboard {
      grid-template-areas: 
        'content'
        'content'
      ;
      &.with-side-bar {
        grid-template-areas: 
          'sideBar            content'
          'sideBar            content'
        ;
      }
    }
  }
}
</style>