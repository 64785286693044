
import { routes_names, layout_names } from '../constants'
// layouts
import AuthLayout from '@/layouts/Auth.vue'
import DashboardLayout from '@/layouts/Dashboard.vue'

export const routes = [
  // auth
  {
    path: '/',
    name: layout_names.authorization,
    component: AuthLayout,
    redirect: {
      name: routes_names.login
    },
    children: [
      {
        path: '/login',
        name: routes_names.login,
        component: () => import('@/views/auth/Login.vue'),
        meta: {
          title: 'Login',
        },
      },
      {
        path: '/signup',
        name: routes_names.signup,
        component: () => import('@/views/auth/Signup.vue'),
        meta: {
          title: 'Signup',
        },
      },
      {
        path: '/verify-email/:token',
        name: routes_names.verify_email,
        component: () => import('@/views/auth/VerifyEmail.vue'),
        meta: {
          title: 'Verify Email',
        },
      }
    ],
    meta: {
      notRequiresAuth: true,
    },
  },
  // main user
  {
    path: '/',
    name: layout_names.dashboard,
    component: DashboardLayout,
    redirect: {
      name: routes_names.main
    },
    children: [
      {
        path: '/dashboard',
        name: routes_names.main,
        component: () => import('@/views/app/index.vue'),
        meta: {
          title: 'Dashboard',
        },
      },
      {
        path: '/account',
        name: routes_names.account,
        component: () => import('@/views/app/account.vue'),
        meta: {
          title: 'Account',
        },
      },
      {
        path: '/session/:session_id',
        name: routes_names.session,
        component: () => import('@/views/app/session.vue'),
        meta: {
          title: 'Session',
        },
      },
      {
        path: '/preferences',
        name: routes_names.preferences,
        component: () => import('@/views/app/preferences.vue'),
        meta: {
          title: 'Preferences',
        },
      },
      {
        path: '/memory',
        name: routes_names.memory,
        component: () => import('@/views/app/memory.vue'),
        meta: {
          title: 'Memory',
        },
      },
      {
        path: '/team',
        name: routes_names.team,
        component: () => import('@/views/app/team.vue'),
        meta: {
          title: 'Team',
        },
      },
      {
        path: '/integrations',
        name: routes_names.integrations,
        component: () => import('@/views/app/integrations.vue'),
        meta: {
          title: 'Integrations',
        },
      },
      {
        path: '/community',
        name: routes_names.community,
        component: () => import('@/views/app/community.vue'),
        meta: {
          title: 'Community',
        },
      },
      {
        path: '/templates-library',
        name: routes_names.templates,
        component: () => import('@/views/app/templates.vue'),
        meta: {
          title: 'Templates Library',
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]