// SECTION - AUTH
export const GET_TOKEN = 'GET_TOKEN'
export const GET_REFRESH_TOKEN = 'GET_REFRESH_TOKEN'
export const GET_TOKEN_PAYLOAD = 'GET_TOKEN_PAYLOAD'
export const GET_IS_LOGGED_IN = 'GET_IS_LOGGED_IN'
// SECTION - APP
export const GET_FETCH_START_UP_RECORD = 'GET_FETCH_START_UP_RECORD'
export const GET_USER = 'GET_USER'
export const GET_TEMPLATES = 'GET_TEMPLATES'
export const GET_FAVOURITE_TEMPLATES = 'GET_FAVOURITE_TEMPLATES'
export const GET_SESSIONS = 'GET_SESSIONS'
// SECTION - SETTING
export const GET_THEME = 'GET_THEME'
export const GET_SYSTEM_THEME = 'GET_SYSTEM_THEME'
export const GET_IS_DARK_MODE = 'GET_IS_DARK_MODE'
export const GET_LANGUAGE = 'GET_LANGUAGE'
// SECTION - ELECTRON
export const GET_UPDATE_STATUS = 'GET_UPDATE_STATUS'
export const GET_UPDATE_AVAILABLE_VERSION = 'GET_UPDATE_AVAILABLE_VERSION'
export const GET_APP_VERSION = 'GET_APP_VERSION'
export const GET_UPDATE_ERROR_MESSAGE = 'GET_UPDATE_ERROR_MESSAGE'
export const GET_UPDATE_DOWNLOAD_PROGRESS = 'GET_UPDATE_DOWNLOAD_PROGRESS'