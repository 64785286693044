import { get_property } from "./filters/getProperty"
import { confirmation } from "./global/confirmation"
import { instance } from "./global/instance"
import { toaster } from "./global/toaster"
import AppTable from "./components/Table.vue"
import AppPinCode from "./components/PinCode.vue"
import AppIcon from "./components/Icon.vue"
import { date_format } from "./filters/dateFormat"
import { duration_format } from "./filters/durationFormat"

export default {
  install(Vue){
    Vue.prototype.$appInstance = instance
    Vue.prototype.$appConfirmation = confirmation
    Vue.prototype.$appToaster = toaster
    // filters
    Vue.filter('get_property',get_property)
    Vue.filter('date_format',date_format)
    Vue.filter('duration_format',duration_format)
    // components
    Vue.component('app-icon',AppIcon)
    Vue.component('app-table',AppTable)
    Vue.component('app-pin-code',AppPinCode)
  }
}