/**
 *
 *
 * @param {{
*  count: Boolean, 
*  source: Boolean, 
*  validation_errors: Boolean, 
*  response_error: Boolean, 
*  status: Boolean, 
*  upload_progress: Boolean, 
*  error_message: Boolean, 
*  send: Any
* }} message
* @return {Object} 
*/
export function apiInstance(message={}){
  const { count, source, abort_controller, validation_errors, response_error, status, upload_progress, error_message, send, error_trigger, } = message
  let obj = {}
  if(count){
    obj.count = 0
    const incrementCount = function(){ 
      this.count=this.count+1 
    }
    obj.increment = incrementCount.bind(obj)
  }
  if(abort_controller) {
    obj.abort_controller = null
  }
  if(source){
    obj.source = null
  }
  if(status){
    obj.status = 0
  }
  if(error_message){
    obj.error_message = ''
  }
  if(validation_errors){
    obj.validation_errors = {}
  }
  if(response_error){
    obj.error = {}
  }
  if(upload_progress){
    obj.upload_progress = {
      total: 0,
      loaded: 0,
      percentage: 0,
      is_process: false,
      onProgress(event){
        this.total=event.total
        this.loaded=event.loaded
        this.percentage=Math.floor((event.loaded / event.total) * 100)
        this.is_process=Math.floor((event.loaded / event.total) * 100) < 100
      },
    }
  }
  if(error_trigger) {
    obj.error_trigger={
      show: false,
      trigger(cb,ms){
        let that = this
        this.show=true
        setTimeout(()=>{
          that.show=false
          cb?.()
        },ms || 1*1000)
      },
    }
  }
  /**
    *
    *
    * @param {{
    *      callBack: Function, 
    *      cancel_message: String
    * }} message
    */
  const reset = function(message){
    const { callBack, cancel_message } = message ?? {}
    if(count) obj.count=0
    if(source) {
      obj.source?.cancel?.(cancel_message??'Cancel Request')
      obj.source=null
    }
    if(abort_controller) {
      obj.abort_controller?.abort?.()
      obj.abort_controller = null
    }
    if(status) obj.status=0
    if(error_message) obj.error_message=''
    if(validation_errors) obj.validation_errors={}
    if(response_error) obj.error={}
    if(upload_progress) {
      obj.upload_progress.total=0
      obj.upload_progress.loaded=0
      obj.upload_progress.percentage=0
      obj.upload_progress.is_process=false
    }
    if(error_trigger) {
      obj.error_trigger.show=false
    }
    obj.send = send ?? false
    callBack?.(obj)
  }
  obj.reset = reset.bind(obj)
  obj.send = send ?? false
  return obj
}