export const themes = {
  dark: {
    value: 'dark',
    text: 'Dark',
  },
  light: {
    value: 'light',
    text: 'Light',
  },
  system_preference: {
    value: 'light',
    text: 'System Preference',
  },
}