import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './plugins/custom-icons';
import './plugins/bootstrap';
import './plugins/vue2-datepicker';
import './plugins/vuelidate';
import i18n from './i18n';
// bootstraping
import Bootstraping from './bootstrap'
Vue.use(Bootstraping)
// --------------
Vue.config.productionTip = false;
new Vue({
  name: 'medical-health',
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

