import Vue from 'vue'
import VueRouter from "vue-router";

import { routes } from './routes';
import { beforeEach } from './hooks/before-each';
import { afterEach } from './hooks/after-each';
import { errorHandling } from './plugin/error-handling';

Vue.use(VueRouter);
const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  //base: '/'+firstPath,
  routes: routes,
});
errorHandling(router)
router.beforeEach(beforeEach);
router.afterEach(afterEach);
export default router;