import { STORAGE } from '@/services';
import createPersistedState from 'vuex-persistedstate';


export default createPersistedState({
  key: STORAGE.AUTH.key,
  paths: [
    `auth.token`,
    `auth.refresh_token`,
  ],
  storage: {
    getItem: (key) => key==STORAGE.AUTH.key ? STORAGE.AUTH.get() : '',
    setItem: (key, value) => key==STORAGE.AUTH.key ? STORAGE.AUTH.set(value) : '',
    removeItem: (key) => key==STORAGE.AUTH.key ? STORAGE.AUTH.remove() : '',
  }
});