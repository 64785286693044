import { ConsoleLogger } from "./ConsolLogger"

export const LOGGER = new ConsoleLogger()  
export const API_LOGGER = new ConsoleLogger({
  name: 'API',
  console: false,
})
export const ELECTRON_LOGGER = new ConsoleLogger({
  name: 'ELECTRON',
})
export const ROUTER_LOGGER = new ConsoleLogger({
  name: 'ROUTER',
})
export const STORAGE_LOGGER = new ConsoleLogger({
  name: 'STORAGE',
})