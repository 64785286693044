import { AUTH_ENDPOINTS } from "./auth/index"
import { FILES_ENDPOINTS } from "./files"
import { SESSIONS_ENDPOINTS } from "./sessions"
import { TEMPLATES_ENDPOINTS } from "./templates"
import { USERS_ENDPOINTS } from "./users"

export const API_ENDPOINTS = {
  auth: AUTH_ENDPOINTS,
  users: USERS_ENDPOINTS,
  sessions: SESSIONS_ENDPOINTS,
  templates: TEMPLATES_ENDPOINTS,
  files: FILES_ENDPOINTS,
}