<template>
  <div 
    class="pincode-input-container"
    :class="{
      'is-invalid': state==false,
      'is-valid': state==true,
    }"
  >
    <b-form-input
      v-for="(item, index) in length"
      :key="item"
      :id="`${_uid}-pin-code-${index}`"
      type="number"
      :value="pin[index]"
      @keydown="onKeyDown($event,index)"
      @paste.prevent="onPaste($event)"
      max="9"
      min="0"
      maxlength="1"
      minlength="1"
      :disabled="disabled"
      :readonly="true"
      onfocus="this.removeAttribute('readonly')"
      onblur="this.setAttribute('readonly','readonly')"
    />
  </div>
</template>

<script>
export default {
  name: "AppPinCode",
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    length: {
      type: Number,
      default: 6,
    },
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      default: null,
    },
  },
  data() {
    return {
      pin: [],
    };
  },
  computed: {
    pinValue() { return Object.values(this.pin).join(""); },
  },
  watch: {
    length: {
      immediate: true,
      handler(length) {
        this.pin = { ...Array.from({length},()=>'') };
      },
    },
    pinValue(pin_val) {
      if (pin_val!=this.value || pin_val==''){
        this.$emit("change", pin_val);
        if(pin_val.length==this.length) {
          this.$emit("complete", pin_val);
        }
      }
    },
    value: {
      immediate: true,
      handler(value) {
        if(value && value.length==this.length){
          this.pin = { ...value.split("") };
        }
      },
    },
  },
  methods: {
    onKeyDown(event,index){
      const key = event.key
      const altKey = event.altKey
      const shiftKey = event.shiftKey
      const ctrlKey = event.ctrlKey
      if(ctrlKey && key=='v') return;
      event.preventDefault()
      const next_el = document.getElementById(`${this._uid}-pin-code-${index+1}`)
      const prev_el = document.getElementById(`${this._uid}-pin-code-${index-1}`)
      if (key == "ArrowLeft") prev_el?.focus();
      else if (key == "ArrowRight") next_el?.focus();
      else if (key == "Backspace"){
        if(this.pin[index]=='') prev_el?.focus();
        this.$set(this.pin,index,'')
      } else if (!altKey && !ctrlKey && !shiftKey && /^[0-9]+$/.test(key)){
        this.$set(this.pin,index,key)
        next_el?.focus();
      }
    },
    onPaste(Event){
      const copy_text = Event.clipboardData.getData('text/plain').replace(/ /g, "")
      if (copy_text.length==this.length && /^[0-9]+$/.test(copy_text)) {
        this.pin = { ...copy_text.split('') }
        document.getElementById(`${this._uid}-pin-code-${Object.values(this.pin).length-1}`)?.focus();
      }
    },
    focus(){
      document.getElementById(`${this._uid}-pin-code-${0}`)?.focus();
    },
  },
};
</script>

<style>


</style>